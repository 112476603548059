import { useVideoJs } from '@/hooks/useVideoJs';
import { RoomContext } from '@/js/shared/context/RoomContext';
import { VideoChatContext } from '@/js/shared/context/VideoChatContext';
import { useUserMonitor } from '@/user-monitor';
import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { useTrack } from '@/js/shared/context/CoeolyticsHooks';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';

import { isMobileDevice } from '@/js/shared/utils/detect';

interface IProps {
  source: {
    poster?: string;
    src: string;
    type: string;
  };
  trackingdata?: {
    programroomId?: string;
    videoTitle?: string;
  };
  children?: React.ReactNode;
  videoStartTime?: any;
  videoDurationTime?: any;
  event?: any;
  videoAssetStartUTC?: any;
  playerEventCallback?: any;
  storyBoardUrl?: any;
  captions?: any;
}
interface Statsdata {
  programroomId?: string;
  videoTitle?: string;
  videoSeries?: string;
}

const useStyles = makeStyles<Theme, { livePlayer: boolean }>((theme) => ({
  video: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    backgroundColor: '#151515',
    '& > .vjs-tech': {
      position: 'relative',
      maxWidth: '100%',
    },
    '& > .vjs-poster': {
      backgroundColor: 'inherit',
      backgroundSize: ({ livePlayer }) => (livePlayer ? 'cover' : 'contain'),
    },
    '&.video-js .vjs-progress-control': {
      visibility: ({ livePlayer }) =>
        livePlayer ? 'hidden !important' : 'visible',
      cursor: ({ livePlayer }) => (livePlayer ? 'default' : 'pointer'),
    },
  },
  actions: {
    top: 0,
    right: '0',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: theme.spacing(1),
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
}));

/**
 * Renders a videoJs player
 *
 * @returns JSX.Element
 * @example
 * <VideoPlayer
 *   source={{
 *     src: 'path/to/video',
 *     type: 'application/x-mpegURL',
 *     poster: 'path/to/posterImg'
 *   }}
 *  trackingdata={{
 *    programroomId: 'rooomId'
 *    videoTitle: 'room title',
 *  }}
 * >
 *   // pass an optional component to render in video header area
 * </VideoPlayer>
 */

export type VideoPlayerHandle = {
  changedStatsMetaData: (data: Statsdata) => void;
};

export const VideoPlayer = React.forwardRef<
  VideoPlayerHandle,
  React.PropsWithChildren<IProps>
>((props: IProps, ref) => {
  const classes = useStyles({ livePlayer: false });

  const { videoRef, playerRef } = useVideoJs({
    liveTracker: {
      trackingThreshold: 2,
    },
    controlBar: {
      timeDivider: false,
      currentTimeDisplay: false,
      durationDisplay: false,
    },
    poster: props.source.poster,
    sources: [
      {
        type: props.source.type,
        src: props.source.src,
      },
    ],
    plugins: {
      mux: {
        debug: false,
        data: {
          env_key: process.env.MUX_ENV_KEY, //required
          // Metadata
          player_name: 'COEO Player',
          player_init_time: Date.now(),
          sub_property_id: props.trackingdata
            ? props.trackingdata.programroomId
            : null,
          video_title: props.trackingdata
            ? props.trackingdata.videoTitle
            : null,
          //video_stream_type: 'live',
        },
      },
    },
  });

  //Emit mux programchange on new currentevent/session
  React.useImperativeHandle(ref, () => ({
    changedStatsMetaData: (data: Statsdata) => {
      // console.log('changedStatsMetaData', data);
      if (playerRef.current) {
        if (!playerRef.current.paused()) {
          console.log('programchange', data);
          //mux is a plugin to videojs
          (playerRef.current as any).mux.emit('programchange', {
            sub_property_id: data.programroomId || null,
            video_title: data.videoTitle || null,
            video_series: data.videoSeries || null,
          });
        }
      }
    },
  }));

  return (
    <div data-vjs-player>
      {props.children && (
        <div className={classes.actions}>{props.children}</div>
      )}
      <video
        ref={videoRef}
        className={`${classes.video} video-js vjs-big-play-centered`}
        playsInline
      />
    </div>
  );
});

interface ILivePlayerProps extends Pick<IProps, 'source'> {
  children?: React.ReactNode;
}

export const VideoPlayerLive: FC<ILivePlayerProps> = ({ source, children }) => {
  const classes = useStyles({ livePlayer: true });

  const { videoChatId } = useContext(VideoChatContext);
  const {
    roomState: { room },
  } = useContext(RoomContext);

  const { hasInteracted } = useUserMonitor();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  const { videoRef, playerRef } = useVideoJs({
    liveTracker: {
      trackingThreshold: 2,
    },
    liveui: true,
    autoplay: !smallScreen && hasInteracted,
    controlBar: {
      timeDivider: false,
      currentTimeDisplay: false,
    },
    poster: source.poster,
    sources: [
      {
        type: source.type,
        src: source.src,
      },
    ],
    plugins: {
      mux: {
        debug: false,
        data: {
          env_key: process.env.MUX_ENV_KEY,
          player_name: 'COEO Player',
          player_init_time: Date.now(),
          sub_property_id: room.id || null,
          video_title: room.title || null,
        },
      },
    },
  });

  useEffect(() => {
    try {
      if (videoChatId === null) {
        playerRef.current && playerRef.current.volume(1);
      } else {
        playerRef.current && playerRef.current.volume(0.15);
      }
    } catch (e) {
      console.log('Could not set volume');
    }
  }, [videoChatId]);

  useEffect(() => {
    if (playerRef.current != null) {
      if (!playerRef.current.paused()) {
        (playerRef.current as any).mux.emit('programchange', {
          sub_property_id: room.id || null,
          video_title: room.title || null,
          video_series: room.currentEvent?.title || null,
        });
      }
    }
  }, [room.currentEventId]);

  return (
    <div data-vjs-player>
      {children && <div className={classes.actions}>{children}</div>}
      <video
        ref={videoRef}
        className={`${classes.video} video-js vjs-big-play-centered`}
        playsInline
      />
    </div>
  );
};

export const VideoPlayerVod = React.forwardRef<
  VideoPlayerHandle,
  React.PropsWithChildren<IProps>
>((props: IProps, ref) => {
  const classes = useStyles({ livePlayer: false });
  const videoassetstartutctime = useRef(0);
  const { hasInteracted } = useUserMonitor();
  const [captions, setCaptions] = useState([]);

  const { videoRef, playerRef } = useVideoJs({
    autoplay: !isMobileDevice() && hasInteracted,
    preload: 'metadata',
    playbackRates: [1, 1.2, 1.3, 1.4, 1.5],
    poster: props.source.poster,
    sources: [
      {
        type: props.source.type,
        src: props.source.src,
      },
    ],

    plugins: {
      mux: {
        debug: false,
        data: {
          env_key: process.env.MUX_ENV_KEY, //required
          // Metadata
          player_name: 'COEO Player',
          player_init_time: Date.now(),
          video_stream_type: 'vod',
        },
      },

      // Example solution eliminating dependency of useEffect and forwardRef.
      //   offset: {
      //     start: props.videoStartTime / 1000,
      //     end: (props.videoStartTime + props.videoDurationTime) / 1000,
      //     restart_beginning: false, //Should the video go to the beginning when it ends
      //   }
    },
  });

  const handleKeyDown = (event: any) => {
    let player = playerRef.current;
    if (event.key == 'ArrowLeft') {
      //left arrow
      player.currentTime(player.currentTime() - 1);
    } else if (event.key == 'ArrowRight') {
      //right arrow
      player.currentTime(player.currentTime() + 1);
    }
  };

  useEffect(() => {
    videoassetstartutctime.current = props.videoAssetStartUTC;
    let player = playerRef.current;

    if (player != null) {
      //Add arrow to navigate forward
      window.addEventListener('keydown', handleKeyDown);

      //tap pause
      /*
      if(isMobileDevice()){
        player.on('touchstart', function (event:any) {
          let player = playerRef.current;
          if (event.target.nodeName === 'VIDEO') {
            if (player.paused()) {
              player.play()
            } else {
              player.pause()
            }
          }
        });
      }
      */

      if (props.storyBoardUrl) {
        if (!isMobileDevice()) {
          // player.vttThumbnails({
          //   src: props.storyBoardUrl,
          //   showTimestamp: true,
          // });
        }
      }

      let start_offset = 0;
      (playerRef.current as any).offset({
        start: start_offset,
      });

      player.on('ended', () => {
        //console.log("ended", player);
        let playerutctime =
          Number(videoassetstartutctime.current) +
          Number(player.currentTime() - start_offset);
        if (props.playerEventCallback instanceof Function) {
          props.playerEventCallback('ended', playerutctime);
        }
      });
      player.on('seeked', () => {
        if (!player.seeking() && !player.scrubbing()) {
          //console.log("seeked")
          let playerutctime =
            Number(videoassetstartutctime.current) +
            Number(player.currentTime() - start_offset);
          if (props.playerEventCallback instanceof Function) {
            props.playerEventCallback('seeked', playerutctime);
          }
        }
      });

      if (props.videoStartTime && props.videoAssetStartUTC) {
        player.on('timeupdate', function () {
          if (!player.scrubbing()) {
            //console.log("timeupdate");
            let playerutctime =
              Number(videoassetstartutctime.current) +
              Number(player.currentTime() - start_offset);
            if (player.currentTime() > 0) {
              if (props.playerEventCallback instanceof Function) {
                props.playerEventCallback('timeupdate', playerutctime);
              }
            }
          }
        });

        //Offset is a loaded videojs plugin
        /*(playerRef.current as any).offset({
        start: props.videoStartTime / 1000,
        end: (props.videoStartTime + props.videoDurationTime) / 1000,
        restart_beginning: false, //Should the video go to the beginning when it ends
      });
      */
      }
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    videoassetstartutctime.current = props.videoAssetStartUTC;
    let player = playerRef.current;
    if (player != null) {
      if (props.videoStartTime < 0) {
        player.currentTime(0);
      } else {
        //Abs check Needed to fix clicks in sound when swap
        if (Math.abs(player.currentTime() - props.videoStartTime) > 1) {
          player.currentTime(props.videoStartTime);
        }
      }
      if (player.paused()) {
        //player.play();
      }
    }
  }, [props.videoStartTime, props.videoAssetStartUTC]);

  useEffect(() => {
    let player = playerRef.current;
    if (player != null) {
      player.src([
        {
          type: props.source.type,
          src: props.source.src,
        },
      ]);

      //player.load();
      player.currentTime(props.videoStartTime);
      var playPromise = player.play();

      if (playPromise !== undefined) {
        playPromise
          .then((_) => {})
          .catch((error) => {
            // Auto-play was prevented
            //console.log("jan error", error);
          });
      }

      //console.log("current src", player.currentSrc());
      //player.play();
    }
  }, [props.source.src]);

  useEffect(() => {
    //console.log("props.storyBoardUrl", props.storyBoardUrl)
    if (!isMobileDevice()) {
      // No storyboard when mobile.
      let player = playerRef.current;
      if (player != null) {
        if (props.storyBoardUrl) {
          // if (typeof player.vttThumbnails.src === 'function') {
          //   player.vttThumbnails.src(props.storyBoardUrl);
          // } else {
          //   player.vttThumbnails({
          //     showTimestamp: true,
          //     src: props.storyBoardUrl,
          //   });
          // }
        }
      }
    }
  }, [props.storyBoardUrl]);

  useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  useEffect(() => {
    const player = playerRef.current;
    if (player != null) {
      player.ready(function () {
        if (JSON.stringify(props.captions) !== JSON.stringify(captions)) {
          props.captions.map((caption: any) => {
            player.addRemoteTextTrack(
              {
                label: caption.subtitle_name,
                srclang: caption.subtitle_language,
                src: `/api/programvideoassets/${caption.programroomvideoassetId}/subtitles/link/${caption.id}`,
              },
              false
            );
          });
          setCaptions(props.captions);
        }
      });
    }
  }, [props.captions]);

  return (
    <div data-vjs-player>
      {props.children && (
        <div className={classes.actions}>{props.children}</div>
      )}
      <video
        ref={videoRef}
        className={`${classes.video} video-js vjs-big-play-centered`}
        playsInline
      />
    </div>
  );
});
