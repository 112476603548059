import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Icon,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { ConferenceContext } from '../context/ConferenceContext';
import C from '../../../../shared/constants/general';
import { UserAgreement } from './useragreement/UserAgreement';

const useStyles = makeStyles(() => ({
  field: {
    padding: '8px 0',
    width: '100%',
  },
  loginHolder: {
    maxWidth: 233,
  },
  agreement: {
    textAlign: 'left',
    fontSize: '0.8rem',
    '& button': {
      fontSize: '0.8rem',
    },
  },
}));

const LoginBox = (props) => {
  const classes = useStyles();
  const { authState, dispatch } = useContext(AuthContext);
  // const { conferenceState } = useContext(ConferenceContext);
  let anonymousLogin = false;
  let directLogin = false;
  try {
    const { conferenceState } = useContext(ConferenceContext); // Hämta Context
    if (conferenceState && conferenceState.conferenceDetail) {
      anonymousLogin =
        conferenceState.conferenceDetail.registration_type ===
        C.CONFERENCE.REG_TYPE.ANONYMOUS;
      if (
        !anonymousLogin &&
        conferenceState.conferenceDetail.allow_auto_login
      ) {
        directLogin = true;
      }
    }
  } catch (error) {
    // Probably used in manager login
  }

  const [user, setUser] = useState({
    type: props.type || 'visitor',
    email: props.email || '',
    password: '',
    agreed: false,
    full_name: '', // For anonymous users
  });
  const [forgotPassword, setForgotPassword] = useState({
    forgot: false,
    email: props.email || '',
  });
  const [loading, setLoading] = useState(false);
  const [showAnonymousLogin, setShowAnonymousLogin] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [forgotSent, setForgotSent] = useState(false);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setUser({
      ...user,
      [name]: value,
    });
  };

  useEffect(() => {
    setErrorMsg('');
  }, [user.agreed]);

  const login = (data) => {
    setLoading(true);

    axios
      .post(
        data.type === 'manager'
          ? `/api/login/manager`
          : directLogin
          ? `/api/sendautologinlink/${window.CONFERENCE_ID}`
          : anonymousLogin && showAnonymousLogin
          ? `/api/visitor/conferences/${window.CONFERENCE_ID}/registeropen`
          : `/api/login/visitor`,
        data
      )
      .then((response) => {
        if (directLogin) {
          setErrorMsg(window.LANG.AUTOLOGIN_MSG);
          setUser({
            ...user,
            email: '',
          });
        } else if (
          window.location.pathname === '/' ||
          window.location.pathname === '/login'
        ) {
          window.location.href = '/live';
        } else {
          dispatch({ type: 'LOGIN_SUCCESS', user: response.data.user });
          // actions.refreshState();
        }
      })
      .catch((error) => {
        console.log('login error', error.response);
        if (!error.response.data) {
          dispatch({ type: 'LOGIN_ERROR', message: 'Login response error' });
          setErrorMsg('Login response error');
        } else if (error.response.data.message) {
          if (error.response.data.message.message) {
            setErrorMsg('Login error');
          } else {
            setErrorMsg(error.response.data.message);
          }
        }
      })
      .finally(() => setLoading(false));
  };

  const sendForgotPassword = (data) => {
    setLoading(true);
    const userType = data.type === 'visitor' ? 'p' : 'm';
    axios
      .post(`/api/forgotpassword/${userType}`, data)
      .then(() => {
        setForgotSent(true);
      })
      .catch((error) => {
        console.log('login error', error.response);
        if (error.response.data) {
          setErrorMsg(error.response.data.message);
        } else {
          setErrorMsg('reset error');
        }
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorMsg('');
    setForgotSent(false);
    console.log('handle submit');
    if (forgotPassword.forgot) {
      if (!user.email) {
        return setErrorMsg('Empty email field');
      }
      sendForgotPassword({
        type: user.type,
        email: user.email,
        conferenceId: props.type !== 'manager' ? window.CONFERENCE_ID : null,
      });
    } else if (directLogin) {
      if (!user.email) {
        return setErrorMsg('Empty email field');
      }
      setLoading(true);
      login({
        email: user.email,
      });
    } else if (anonymousLogin && showAnonymousLogin) {
      const trimmedName = user.full_name.trim();
      if (!user.agreed) {
        return setErrorMsg('agreed');
      }

      if (trimmedName.length < 3) {
        return setErrorMsg('Name too short');
      }

      login({
        form: { full_name: trimmedName },
      });
    } else {
      if (!user.email || !user.password) {
        return setErrorMsg('Empty field');
      }
      login({
        type: user.type,
        email: user.email,
        password: user.password,
        conferenceId: props.type !== 'manager' ? window.CONFERENCE_ID : null,
      });
    }
  };
  const toggleForgotPassword = (e) => {
    e.preventDefault();
    setErrorMsg('');
    setForgotSent(false);
    setForgotPassword({
      ...forgotPassword,
      forgot: !forgotPassword.forgot,
    });
  };
  const toggleAnonymousLogin = (e) => {
    e.preventDefault();
    setErrorMsg('');
    setShowAnonymousLogin(!showAnonymousLogin);
  };

  return (
    <Box display="flex" justifyContent="center" pt={3}>
      {authState.userLoggedIn ? (
        <Box>Inloggad</Box>
      ) : directLogin ? (
        <Paper>
          <form onSubmit={handleSubmit}>
            <Box className={classes.loginHolder}>
              <Typography variant="h5" gutterBottom>
                {window.LANG.AUTOLOGIN_TITLE}
              </Typography>
              <Box pb={1}>
                <TextField
                  disabled={loading}
                  type="email"
                  name="email"
                  value={user.email}
                  onChange={handleInputChange}
                  label="Email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>account_circle</Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              {errorMsg && errorMsg !== 'agreed' && (
                <FormHelperText error>{errorMsg}</FormHelperText>
              )}

              <Box textAlign="right">
                <Button
                  disabled={loading}
                  variant="contained"
                  type="submit"
                  color="primary"
                >
                  {window.LANG.AUTOLOGIN_BTN}
                  {loading && <CircularProgress size={24} />}
                </Button>
              </Box>
            </Box>
          </form>
        </Paper>
      ) : anonymousLogin && showAnonymousLogin ? (
        <Paper>
          <form onSubmit={handleSubmit}>
            <Box className={classes.loginHolder}>
              <Typography variant="h5" gutterBottom>
                {window.LANG.ANON_TITLE}
              </Typography>
              <Box pb={1}>
                <TextField
                  className={classes.field}
                  disabled={loading}
                  name="full_name"
                  type="text"
                  value={user.full_name}
                  onChange={handleInputChange}
                  label={window.LANG.ANON_FIELD}
                />
              </Box>
              {errorMsg && errorMsg !== 'agreed' && (
                <FormHelperText error>{errorMsg}</FormHelperText>
              )}
              <Box className={classes.agreement}>
                <UserAgreement
                  errors={errorMsg === 'agreed' ? { agreed: 'agreed' } : {}}
                  values={{ agreed: user.agreed }}
                  onChange={(val) => {
                    setUser({
                      ...user,
                      agreed: val,
                    });
                  }}
                />
              </Box>
              <Box textAlign="right">
                <Button
                  disabled={loading}
                  variant="contained"
                  type="submit"
                  color="primary"
                >
                  {window.LANG.ANON_BTN}
                  {loading && <CircularProgress size={24} />}
                </Button>
              </Box>
              <Box>
                <br />
                <Link
                  href="#"
                  color="primary"
                  component="button"
                  onClick={toggleAnonymousLogin}
                >
                  {window.LANG.OR_LOGIN}
                </Link>
              </Box>
            </Box>
          </form>
        </Paper>
      ) : (
        <Paper>
          <Box pt={1} pb={1} className={classes.loginHolder}>
            <Typography variant="h5" gutterBottom>
              {forgotPassword.forgot
                ? window.LANG.NO_PASSWORD
                : user.type === 'manager'
                ? 'Manager Login'
                : window.LANG.LOGIN}
            </Typography>

            {forgotSent && (
              <Box pb={2}>
                <Typography variant="body2" gutterBottom>
                  {window.LANG.NO_PASSWORD_DONE}
                </Typography>
              </Box>
            )}
            <form onSubmit={handleSubmit}>
              <Box pb={2}>
                <Box>
                  <FormControl>
                    <TextField
                      disabled={loading}
                      type="email"
                      name="email"
                      value={user.email}
                      onChange={handleInputChange}
                      label="Email"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon>account_circle</Icon>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {!forgotPassword.forgot && (
                      <TextField
                        disabled={loading}
                        autoFocus={props.email ? true : false}
                        name="password"
                        type="password"
                        value={user.password}
                        onChange={handleInputChange}
                        label="Password"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon>lock</Icon>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </FormControl>
                </Box>
                {errorMsg && <FormHelperText error>{errorMsg}</FormHelperText>}
                {/* {authState.forgotError && forgotPassword.forgot && (
                    <FormHelperText error>
                      {authState.forgotError}
                    </FormHelperText>
                  )} */}
              </Box>
              <Box align="right">
                <Button
                  disabled={loading}
                  variant="contained"
                  type="submit"
                  color="primary"
                  startIcon={forgotPassword.forgot ? <Icon>email</Icon> : null}
                >
                  {forgotPassword.forgot
                    ? forgotSent
                      ? window.LANG.NO_PASSWORD_SEND_AGAIN
                      : window.LANG.NO_PASSWORD_SEND
                    : window.LANG.LOGIN}{' '}
                  {loading && <CircularProgress size={24} />}
                </Button>
              </Box>
            </form>
            <Box>
              <br />
              <Link component="button" onClick={toggleForgotPassword}>
                {forgotPassword.forgot
                  ? window.LANG.NO_PASSWORD_BACK
                  : window.LANG.NO_PASSWORD}
              </Link>

              {anonymousLogin && !showAnonymousLogin && (
                <>
                  <br />
                  <Link
                    href="#"
                    color="primary"
                    component="button"
                    onClick={toggleAnonymousLogin}
                  >
                    {window.LANG.ANON_TITLE}
                  </Link>
                </>
              )}
            </Box>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default LoginBox;
